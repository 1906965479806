import React, {Component} from 'react';

import Dropzone from 'react-dropzone'
import qif2json from 'qif2json';
import { CSVLink } from "react-csv";
const moment = require('moment');

class QIF2CSV extends Component {

  constructor(props) {
    super(props);
    this.state = { 
  
      // Initially, no file is selected 
      selectedFile: null,
      data: [
      ],
      headers: [
        { label: "Date", key: "date" },
        { label: "Amount", key: "amount" },
        { label: "Description", key: "description" }
      ]
    };
  }
   
  // On file select (from the pop up) 
  onFileChange = files => { 
    // Update the state.
    this.setState({ selectedFile: files[0] });
    // Process the upload into a CSV.
    this.onFileUpload();
  }; 

  onFileUpload = async () => { 
    let reader = new FileReader()
    const getText = () => new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
    });
    reader.readAsText(this.state.selectedFile, 'utf-8');
    const text = await getText();

    qif2json.parseInput(text, {encoding: 'utf-8'}, async (err, data) => {
      if(err) {
        alert(err);
      } else {
        let newData = [];
        for (var datum of data.transactions) {
          newData.push({
            date: (moment(datum.date)).format('DD/MM/YYYY'),
            amount: datum.amount,
            description: datum.payee.split(',').slice(0, -1).join(',').substring(0, 45),
          });
        }
        this.setState({data: newData});
      }
    });
  }; 
  
  // To be displayed when conversion has happened.
  fileData = () => { 
    if (this.state.data.length && this.state.selectedFile) {   
      return ( 
        <div> 
          <h3 className="text-xl text-center mb-4 mt-8 border-b-2 border-indigo-700 inline-block">Converted file</h3>
          <p>
          Download <CSVLink className="text-indigo-700 hover:text-indigo-600 hover:underline" data={this.state.data} headers={this.state.headers} filename={this.state.selectedFile.name + '.csv'}>
            {this.state.selectedFile.name + '.csv'}
          </CSVLink>
          </p>
        </div> 
      ); 
    } 
  }; 

  render() { 
     
    return ( 
      <div> 
          <Dropzone accept=".qif"  onDrop={acceptedFiles => this.onFileChange(acceptedFiles)}>
            {({getRootProps, getInputProps}) => (
              <section className="max-w-xl border-dashed border-2 border-indigo-700 my-3 mx-auto">
                <div {...getRootProps()}>
                  <input className="" {...getInputProps()} />
                  <p className="py-3 px-6 focus:outline-none focus:border-indigo-600">Drag 'n' drop some files here, or click to select files</p>
                </div>
              </section>
            )}
          </Dropzone>
        {this.fileData()} 
      </div> 
    ); 
  }

}

export default QIF2CSV;
