import '../css/App.css';
import React, {Component} from 'react';
import { Link } from "gatsby";
import QIF2CSV from '../components/QIF2CSV/QIF2CSV';
import CapitalOne from '../components/CapitalOne/CapitalOne';
import AmericanExpress from '../components/AmericanExpress/AmericanExpress';

class App extends Component {
  render() {   
    return ( 
      <div>
        <div className="text-center">
          <h1 className="text-5xl text-center mb-8 mt-4 border-b-4 border-indigo-700 inline-block">
            Goodbudget tools
          </h1>
        </div>
        <div className="text-center mb-8">
          <p className="mb-4"><a href="https://goodbudget.com/" className="text-indigo-700 hover:text-indigo-600 hover:underline">GoodBudget</a> is a brilliant website and app that provides budgeting stuff.</p>
          <p className="mb-4">Sometimes it needs a little help to import data from other places.</p>
        </div>
        <div className="text-center mb-8">
          <h2 className="text-2xl text-center mb-4 border-b-2 border-indigo-700 inline-block"> 
            QIF to CSV converter
          </h2> 
          <p className="mb-4">This is designed to take a QIF export from Santander UK and convert to a Goodbudget compatible CSV.</p>
          <QIF2CSV></QIF2CSV>
        </div>
        <div className="text-center mb-8">
          <h2 className="text-2xl text-center mb-4 border-b-2 border-indigo-700 inline-block"> 
            Capital One CSV converter
          </h2> 
          <p className="mb-4">This is designed to take a CapitalOne transaction export and make it compatible for Goodbudget import.</p>
          <CapitalOne></CapitalOne>
        </div>
        <div className="text-center mb-8">
          <h2 className="text-2xl text-center mb-4 border-b-2 border-indigo-700 inline-block"> 
          American Express CSV converter
          </h2> 
          <p className="mb-4">This is designed to take a American Express csv transaction export and make it compatible for Goodbudget import.</p>
          <AmericanExpress></AmericanExpress>
        </div>
        <div className="text-center">
          <footer className="App-footer mt-8">
          <Link to="/" className="rounded-md py-3 px-6 bg-white hover:bg-indigo-50 border border-indigo-500 hover:border-indigo-800 shadow-md">Home</Link>
          </footer>
        </div> 
      </div>
    ); 
  }

}

export default App;
